.overview {
  flex: 5;
  padding: 90px 25px 90px 125px;
}

.overviewWidget {
  display: flex;
  margin: 20px;
}

.MuiDataGrid-root {
  /* height: 100vh !important; */
}

.speedo-text-container {
  padding: 0px 40px;
  text-align: center;
}

.completion-container {
  display: flex;
  align-items: center;
}
.completion-container .response-block {
  width: 11px;
  height: 28px;
  background: #13aaff;
  border-radius: 20px;
  margin-right: 10px;
}

.completion-response-chart {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}
.completion-response-chart .chart-content {
  text-align: center;
}

.completion-response-chart .chart-content .chart-title {
  margin-bottom: 30px;
}

.bar-chart {
  width: inherit !important;
  min-height: 500px;
}

.phone-msg {
  display: flex;
  flex-direction: column;
}

.msg-text {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
}

.msg-sub-text {
  padding-bottom: 10px;
}

.btn-add-phone {
}

.main-phone {
  display: flex;
  justify-content: center;
}

.phone-form > label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.phone-form > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.form-main {
  padding-top: 25px;
}

.phone-btn-add {
  display: flex;
  justify-content: end;

  padding-top: 40px;
  padding-bottom: 20px;
}

.sidebar {
    /* flex:1; */
    height: 100vh;
    background-color: #ffffff;
    position: sticky;
    top: 0px;
}

.sidebarWrapper {
    padding: 20px;
    color: white;
}

.sidebarMenu {
    margin-bottom: 10px;
}

.sidebarlist {
    list-style: none;
    padding: 5px;
}

.sidebarlistbottom {
    list-style: none;
    padding: 5px 25px;
    bottom: 0px;
    position: absolute;
    text-decoration: none;
}

.sidebarlistbottom a {
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    display: flex;
}

.sidebarlistItem {
    padding: 15px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
}

.sidebarlistItembottom {
    padding: 15px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
}

.sidebarlistItem.active,
.sidebarlistItem:hover {
    background-color: white;
    color: #ef5663;
}

.makeStyles-drawerClose-18 {
    background-color: #ffffff !important;
    z-index: 1300;
}

.makeStyles-drawer-16 {
    z-index: 1300;
}

.makeStyles-appBar-23 {
    z-index: 1200 !important;
}

/*
.sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root{color: #ef5663 !important;
    stroke: #ffffff;
    stroke-width: 1;}



.sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root{color: #ef5663 !important;
    stroke: #ffffff;
    stroke-width: 1;}*/


/* .sidebarlistItem.active,
.sidebarlistItem:hover {
    background-color: white;
    color: #ef5663;
} */

.sidebarIcon {
    margin-right: 5px;
    font-size: 30px !important;
    height: 28px !important;
    width: 28px !important;
}

.sidebarIcon.sms {
    background-color: #fcf2f2;
    padding: 25px 30px;
    border-radius: 100%;
    color: #da524c;
    height: 110px !important;
    width: 110px !important;
    font-size: 40px !important;
    stroke: #fcf2f2;
    stroke-width: 1;
}

.sidebarIcon.whatsapp {
    background-color: #f3fcf4;
    padding: 25px 30px;
    border-radius: 100%;
    color: #79d175;
    height: 110px !important;
    width: 110px !important;
    font-size: 40px !important;
    stroke: #f3fcf4;
    stroke-width: 1;
}

.sidebarIcon.email {
    background-color: #f0f6fe;
    padding: 25px 30px;
    border-radius: 100%;
    color: #52ace9;
    height: 110px !important;
    width: 110px !important;
    font-size: 40px !important;
    stroke: #f0f6fe;
    stroke-width: 1;
}

.sidebarIcon.chats {
    background-color: #fdfcea;
    padding: 25px 30px;
    border-radius: 100%;
    color: #f0a73f;
    height: 110px !important;
    width: 110px !important;
    font-size: 40px !important;
    stroke: #fdfcea;
    stroke-width: 1;
}

.sidebarIcon.video {
    background-color: #f4f3fe;
    padding: 25px 30px;
    border-radius: 100%;
    color: #962cf6;
    height: 110px !important;
    width: 110px !important;
    font-size: 40px !important;
    stroke: #f4f3fe;
    stroke-width: 1;
}

.logoadmin {
    display: flex;
    margin-bottom: 20px;
}

.logowrapper {
    display: flex;
    justify-content: center;
}

/* .headerMainWrapper {
    display: flex;
    justify-content: center;
} */

/* .makeStyles-appBar-1{z-index:1 !important;}
.MuiIconButton-edgeStart{min-width: 85px !important;}
.makeStyles-toolbar-8{padding:12px 0px 0px 0px!important; align-items:flex-start !important;;}
.makeStyles-menuButton-14{margin-right:0px !important; padding: 0px !important;}
.makeStyles-menuButton-3{margin-right:0px !important; padding: 0px !important}
.MuiIconButton-edgeStart{margin-left:0px !important;}
.makeStyles-menuButton-3 svg.MuiSvgIcon-root{color:#ffffff !important;} */


#sms:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

#sms.MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

#sms.MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

/*#sms.MuiListItem-button:hover .filter-red{ filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%); }
#sms.MuiListItem-button:hover{ background-color: #FFEDF0; height: 60px; border-radius: 10px; }
#sms.MuiListItem-button:hover span{ color:#EF5663 !important;}*/
#sms.MuiListItem-button.active span {
    color: #EF5663 !important;
}

#sms.MuiListItem-button {
    height: 60px;
    /*margin:5px 2px;*/
}

#sms:hover svg {
    color: #EF5663;
}

#sms.active svg {
    color: #EF5663;
}

#sms svg {
    color: #B5B3B4;
}

#sms .MuiListItemText-root span {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

/* .MuiCollapse-wrapperInner{margin-top:-30px} */

.sms-channel-menu {
    border-radius: 10px;
    background-color: #FFEDF0 !important;
}

/* .sms-channel-menu .MuiCollapse-root {} */

.normal-sms-channel-menu:hover {
    background: #FFEDF0 !important;
    border-radius: 10px;
}

/* .MuiCollapse-wrapperInner{margin-top:-30px} */

#sendSMS {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
    border-radius: 15px;
    border: 5px solid #27AE60;
}

/*#sendSMS:hover .MuiListItem-button{background-color: #EF5663 !important; height: 60px; border-radius: 10px; border: 5px solid #EF5663;}
#sendSMS:hover .MuiListItemIcon-root  .filter-white{ filter: brightness(0) invert(1);}*/
#sendSMS .MuiListItem-button.active {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    border: 5px solid #27AE60;
}

/*#sendSMS .MuiListItem-button.active .filter-white{ filter: brightness(0) invert(1);}*/

#contactsid span {
    font-size: 18px;
}

.normal-email-channel-menu:hover {
    background: #DFEDFA !important;
    border-radius: 10px;
}

.normal-wp-channel-menu:hover {
    background: #DFF3E7;
    border-radius: 10px;
}

.wp-channel-menu {
    background: #DFF3E7;
    border-radius: 10px;
}

.makeStyles-list-10 .MuiListItem-button {
    height: 60px;
    padding-left: 30px;
}

#scheduleSMS {
    height: 60px !important;
    display: block;
}

/*#scheduleSMS:hover .MuiListItem-button{background-color: #EF5663 !important; height: 60px;  }
#scheduleSMS:hover .MuiListItemIcon-root  .filter-white{ filter: brightness(0) invert(1);}*/
#scheduleSMS .MuiListItem-button.active {
    background-color: #EF5663 !important;
    height: 60px;
}

#scheduleSMS .MuiListItem-button.active .filter-white {
    filter: brightness(0) invert(1);
}

/********* email channel css ***********/

.email-channel-menu {
    border-radius: 10px;
    background-color: #DFEDFA !important;
}

#email:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

#email.MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

#email.MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

#email.MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

#email.MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

#email.MuiListItem-button:hover span {
    color: #EF5663 !important;
}

#email.MuiListItem-button.active span {
    color: #EF5663 !important;
}

#email.MuiListItem-button {
    height: 60px;
    /*margin:5px 2px;*/
}

#email:hover svg {
    color: #EF5663;
}

#email.active svg {
    color: #EF5663;
}

#email svg {
    color: #B5B3B4;
}

#email .MuiListItemText-root span {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#sendEmail {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

/*#sendEmail:hover .MuiListItem-button{background-color: #EF5663 !important; height: 60px;  }
#sendEmail:hover .MuiListItemIcon-root  .filter-white{ filter: brightness(0) invert(1);}*/
#sendEmail .MuiListItem-button.active {
    background-color: #EF5663 !important;
    height: 60px;
}

#sendEmail .MuiListItem-button.active .filter-white {
    filter: brightness(0) invert(1);
}

#scheduleEmail {
    height: 60px !important;
    display: block;
}

/*scheduleEmail:hover .MuiListItem-button{background-color: #EF5663 !important; height: 60px;  }
#scheduleEmail:hover .MuiListItemIcon-root  .filter-white{ filter: brightness(0) invert(1);}*/
#scheduleEmail .MuiListItem-button.active {
    background-color: #EF5663 !important;
    height: 60px;
}

#scheduleEmail .MuiListItem-button.active .filter-white {
    filter: brightness(0) invert(1);
}

#logoutid {
    position: absolute;
    bottom: 10px;
}

#logoutid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

#logoutid:hover .MuiListItemIcon-root svg {
    color: #ffffff !important;
    stroke: #1d2740
}

#logoutid .MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

#logoutid .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

#logoutid .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

#logoutid .MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

#logoutid .MuiListItem-button.active span {
    color: #EF5663 !important;
}

#logoutid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

#logoutid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

#logoutid .MuiListItem-button span {
    color: #B5B3B4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#logoutid:hover img.filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

.MuiDrawer-root .MuiList-root {
    height: 100vh;
}

a#chatid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#chatid:hover .MuiListItemIcon-root svg {
    color: #ffffff !important;
    stroke: #1d2740
}

a#chatid .MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#chatid .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#chatid .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#chatid .MuiListItem-button:hover {
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#chatid .MuiListItem-button.active span {
    color: #EF5663 !important;
}

a#chatid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

a#chatid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#chatid .MuiListItem-button span {
    color: #B5B3B4 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}


.makeStyles-list-11 #sendSMS-channel .MuiListItem-button:hover,
.makeStyles-list-11 #scheduleSMS-channel .MuiListItem-button:hover,
.makeStyles-list-11 #sendEmail-channel .MuiListItem-button:hover,
.makeStyles-list-11 #scheduleEmail-channel .MuiListItem-button:hover,
.makeStyles-list-11 #wpid .MuiListItem-button:hover,
.makeStyles-list-11 #wparchiveid .MuiListItem-button:hover,
.makeStyles-list-11 #campaignanalyticsid .MuiListItem-button:hover,
.makeStyles-list-11 #historyanalyticsid .MuiListItem-button:hover {
    background: transparent !important;
}

/*.makeStyles-list-23 .MuiListItem-button:hover {
    background: none !important;
}*/

a#contactsid:hover .MuiListItemIcon-root svg {
    color: #27AE60 !important;
    stroke: #1d2740
}

a#contactsid .MuiListItem-button.active {
    color: #ffffff !important;
    background-color: #d9ead3;
    height: 60px;
    border-radius: 10px;
}

a#contactsid .MuiListItem-button .filter-red {
    filter: invert(56%) sepia(47%) saturate(5165%) hue-rotate(113deg) brightness(101%) contrast(69%);
}

a#contactsid .MuiListItem-button.active .filter-red {
    filter: invert(56%) sepia(47%) saturate(5165%) hue-rotate(113deg) brightness(101%) contrast(69%);
}

a#contactsid .MuiListItem-button:hover .filter-red {
    filter: invert(56%) sepia(47%) saturate(5165%) hue-rotate(113deg) brightness(101%) contrast(69%);
}

* a#contactsid .MuiListItem-button:hover {
    background-color: #d9ead3 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#contactsid .MuiListItem-button.active span {
    color: #27AE60 !important;
}

a#contactsid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

a#contactsid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#contactsid .MuiListItem-button span {
    color: #27AE60 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.contact-channel-menu {
    background: #DFF3E7;
    border-radius: 10px;
}

/*.normal-email-channel-menu {
    background: #DFEDFA !important;
    border-radius:10px;
}*/

.normal-contact-channel-menu:hover {
    background: #DFF3E7;
    border-radius: 10px;
}

.contact-channel-menu {
    background: #DFF3E7;
    border-radius: 10px;
}

a#logsid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#logsid:hover .MuiListItemIcon-root svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#logsid .MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#logsid .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#logsid .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#logsid .MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#logsid .MuiListItem-button.active span {
    color: #EF5663 !important;
}

a#logsid .MuiListItem-button.active svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#logsid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#logsid .MuiListItem-button span {
    color: #B5B3B4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

a#stripeid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#stripeid:hover .MuiListItemIcon-root svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#stripeid .MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#stripeid .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#stripeid .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#stripeid .MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#stripeid .MuiListItem-button.active span {
    color: #EF5663 !important;
}

a#stripeid .MuiListItem-button.active svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#stripeid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#stripeid .MuiListItem-button span {
    color: #B5B3B4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

a#voiceChannelId:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#voiceChannelId:hover .MuiListItemIcon-root svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#voiceChannelId .MuiListItem-button.active {
    color: #EF5663 !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#voiceChannelId .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#voiceChannelId .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#voiceChannelId .MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#voiceChannelId .MuiListItem-button.active span {
    color: #EF5663 !important;
}

a#voiceChannelId .MuiListItem-button.active svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#voiceChannelId .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#voiceChannelId .MuiListItem-button span {
    color: #B5B3B4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

/* Tasks */
/* a#tasksid .MuiButtonBase-root{background-color: #ef5663;} */

a#tasksid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#tasksid:hover .MuiListItemText-root span {
    color: #5C09EE !important;
}

a#tasksid:hover .MuiListItemIcon-root svg {
    color: #5C09EE !important;
    stroke: #1d2740
}

a#tasksid .MuiListItemIcon-root svg {
    color: #5C09EE !important;
    stroke: #1d2740
}

a#tasksid .MuiListItem-button.active {
    color: #F5F5DC !important;
    background-color: #C6ABF5;
    height: 60px;
    border-radius: 10px;
}

a#tasksid .MuiListItem-button .filter-red {
    color: #5C09EE;
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#tasksid .MuiListItem-button.active .filter-red {
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#tasksid .MuiListItem-button:hover .filter-red {
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#tasksid .MuiListItem-button:hover {
    background-color: #C6ABF5 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#tasksid .MuiListItem-button.active span {
    color: #5C09EE !important;
}

a#tasksid .MuiListItem-button.active svg {
    color: #5C09EE !important;
    stroke: #1d2740
}

a#tasksid .MuiListItem-button span {
    color: #5C09EE !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

.headerMenus {
    position: absolute;
    top: 70px;
    right: 30px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    border-radius: 8px;
    width: 100%;
    max-width: 240px;
}

.headerMenus::before {
    position: absolute;
    top: -5px;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    right: 25px;
    content: '';
    transform: rotate(45deg);
}

.headerMessages {
    position: absolute;
    top: 65px;
    right: 205px;
    padding: 20px;
    background-color: white;
    width: 100%;
    max-width: 400px;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    border-radius: 8px;
}

.headerMessages::before {
    position: absolute;
    top: -5px;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    right: 25px;
    content: '';
    transform: rotate(45deg);
}

.headerNotifications {
    position: absolute;
    top: 65px;
    right: 270px;
    padding: 20px;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    border-radius: 8px;
    background-color: white;
    width: 100%;
    max-width: 400px;
}

.headerNotifications::before {
    position: absolute;
    top: -5px;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    box-shadow: 6px -4px 105px rgba(44, 33, 38, 0.1);
    right: 25px;
    content: '';
    transform: rotate(45deg);
}

.headerRightSide img.notifications {
    cursor: pointer;
    margin-right: 35px;
    margin-left: 0px;
}

.headerRightSide img.messages {
    cursor: pointer;
    margin-right: 35px;
    margin-left: 0px;
}


.companyLogo {
    max-width: 100px;
    border-radius: 8px;
    padding: 5px;
}

.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap {
    width: 100%;
}

.pageHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}

.pageHeaderNamesWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d1cfd0;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.profileavatar {
    border-radius: 8px;
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.headerRightSide {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    min-width: 230px;
}

.headerRightSide img {
    margin: 0px 5px 0px 0px;
}

.profileTitle {
    color: #2C2126;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize !important;
    line-height: 20px;
}

.profileRole {
    font-family: 'Manrope';
    font-style: normal;
    text-transform: capitalize !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Greys/Blue Grey/500 */
    color: #B5B3B4;
}

.profileNames {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 10px;
}

@media only screen and (max-width: 768px) {
    .makeStyles-menuButton-3 {
        margin-right: 5px !important;
    }

    .headerNotifications {
        right: 215px;
    }

    .headerMessages {
        right: 175px;
    }
}

.sidebarlogo {
    margin: 10px 30px 10px 0px;
}

.main-sidebar {
    padding: 8px 5px 8px 5px;
}

.normal-analyticsid-menu {
    border-radius: 10px;
    background-color: #ffffff !important;
}

.normal-analyticsid-menu:active {
    border-radius: 10px;
    background-color: #eddce6 !important;
}

.normal-analyticsid-menu:hover {
    border-radius: 10px;
    background-color: #eddce6 !important;
}

.analyticsid-menu {
    border-radius: 10px;
    background-color: #eddce6 !important;
}

.nav {
    margin-top: 5px;
}

.MuiCollapse-entered {
    width: 100% !important;
}

.infobar {
    position: relative;
    display: block;
    color: #004487;
    margin: 5px;
}

.infobar .count {
    position: absolute;
    right: 0;
}


/* ================================================================ */
/* ================================================================ */
/* Overview - Sidebar */
/* ================================================================ */
/* ================================================================ */

/* a#overviewid .MuiButtonBase-root{background-color: #ef5663;} */

a#overviewid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#overviewid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#overviewid:hover .MuiListItemIcon-root svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#overviewid .MuiListItem-button.active {
    color: #F5F5DC !important;
    background-color: #FCF7E6;
    height: 60px;
    border-radius: 10px;
}

a#overviewid .MuiListItem-button .filter-red {
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#overviewid .MuiListItem-button.active .filter-red {
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#overviewid .MuiListItem-button:hover .filter-red {
    filter: invert(63%) sepia(41%) saturate(863%) hue-rotate(354deg) brightness(100%) contrast(106%);
}

a#overviewid .MuiListItem-button:hover {
    background-color: #FCF7E6 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#overviewid .MuiListItem-button.active span {
    color: #FFA412 !important;
}

a#overviewid .MuiListItem-button.active svg {
    color: #FFA412 !important;
    stroke: #1d2740
}

a#overviewid .MuiListItem-button span {
    color: #FFA412 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

/* ================================================================ */
/* ================================================================ */
/* Channel - Sidebar */
/* ================================================================ */
/* ================================================================ */

a#channelsid:hover .MuiListItemText-root span {
    color: #A700FF !important;
}

a#channelsid:hover .MuiListItemIcon-root svg {
    color: #A700FF !important;
    stroke: #1d2740
}

a#channelsid .MuiListItem-button.active {
    color: #A700FF !important;
    background-color: #f2d9ff;
    height: 60px;
    border-radius: 10px;
}

a#channelsid .MuiListItem-button .filter-red {
    filter: invert(26%) sepia(100%) saturate(7015%) hue-rotate(278deg) brightness(95%) contrast(130%);
}

a#channelsid .MuiListItem-button.active .filter-red {
    filter: invert(26%) sepia(100%) saturate(7015%) hue-rotate(278deg) brightness(95%) contrast(130%);
}

a#channelsid .MuiListItem-button:hover .filter-red {
    filter: invert(26%) sepia(100%) saturate(7015%) hue-rotate(278deg) brightness(95%) contrast(130%);
}

a#channelsid .MuiListItem-button:hover {
    background-color: #f2d9ff !important;
    height: 60px;
    border-radius: 10px;
}

a#channelsid .MuiListItem-button.active span {
    color: #A700FF !important;
}

a#channelsid .MuiListItem-button span {
    color: #A700FF !important;
}

a#channelsid .MuiListItem-button.active svg {
    color: #A700FF !important;
    stroke: #1d2740
}

a#channelsid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#channelsid .MuiListItem-button span {
    color: #A700FF;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

/* ================================================================ */
/* ================================================================ */
/* User - Sidebar */
/* ================================================================ */
/* ================================================================ */

a#usersid:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

a#usersid:hover .MuiListItemIcon-root svg {
    color: #EF5663 !important;
    stroke: #1d2740
}

a#usersid .MuiListItem-button.active {
    color: #ffffff !important;
    background-color: #FFEDF0;
    height: 60px;
    border-radius: 10px;
}

a#usersid .MuiListItem-button .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#usersid .MuiListItem-button.active .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#usersid .MuiListItem-button:hover .filter-red {
    filter: invert(41%) sepia(53%) saturate(2136%) hue-rotate(326deg) brightness(102%) contrast(87%);
}

a#usersid .MuiListItem-button:hover {
    background-color: #FFEDF0 !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#usersid .MuiListItem-button.active span {
    color: #EF5663 !important;
}

a#usersid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

a#usersid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#usersid .MuiListItem-button span {
    color: #EF5663;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

/* ================================================================ */
/* ================================================================ */
/* Template - Sidebar */
/* ================================================================ */
/* ================================================================ */

a#templatesid:hover .MuiListItemText-root span {
    color: #2DD4BF !important;
}

a#templatesid:hover .MuiListItemIcon-root svg {
    color: #2DD4BF !important;
    stroke: #1d2740
}

a#templatesid .MuiListItem-button.active {
    color: #ffffff !important;
    background-color: #c4efea;
    height: 60px;
    border-radius: 10px;
}

/*a#templatesid .MuiListItem-button .filter-red{ filter: invert(80%) sepia(60%) saturate(562%) hue-rotate(106deg) brightness(88%) contrast(87%);}
    a#templatesid .MuiListItem-button.active .filter-red{ filter: invert(80%) sepia(60%) saturate(562%) hue-rotate(106deg) brightness(88%) contrast(87%);}
    a#templatesid .MuiListItem-button:hover .filter-red{ filter: invert(80%) sepia(60%) saturate(562%) hue-rotate(106deg) brightness(88%) contrast(87%); }*/
a#templatesid .MuiListItem-button:hover {
    background-color: #c4efea !important;
    ;
    height: 60px;
    border-radius: 10px;
}

a#templatesid .MuiListItem-button.active span {
    color: #2DD4BF !important;
}

a#templatesid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

a#templatesid .MuiListItem-button {
    height: 60px;
    margin: 5px 2px;
}

a#templatesid .MuiListItem-button span {
    color: #2DD4BF !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

/* ================================================================ */
/* ================================================================ */
/* SMS Channel - Sidebar */
/* ================================================================ */
/* ================================================================ */

#sms-channel:hover .MuiListItemText-root span {
    color: #EF5663 !important;
}

#sms-channel.MuiListItem-button.active span {
    color: #FFF !important;
}

#sms-channel.MuiListItem-button {
    height: 60px;
}

#sms-channels.active svg {
    color: #EF5663;
}

#sms-channel svg {
    color: #B5B3B4;
}

#sms-channel .MuiListItemText-root span {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#sms-channel.MuiListItem-button.active {
    border-radius: 10px;
    background: var(--primary-hover, #F05865);
}

#sms-channel.MuiListItem-button.active .MuiSvgIcon-root {
    color: #fff;
}

#sms-channel.MuiListItem-button.active:hover {
    background-color: #EF5663;
    height: 60px;
    border-radius: 10px;
}

#sms-channel.MuiListItem-button:hover {
    background-color: #FFEDF0;
    height: 60px;
}

#sms-channel .MuiListItemText-root span {
    color: #F05865;
}

#sms-channel svg {
    color: #F05865;
}

#sms-channel .filter-red {
    filter: invert(48%) sepia(28%) saturate(3204%) hue-rotate(322deg) brightness(97%) contrast(94%);
}

#sms-channel.active .filter-red {
    filter: brightness(0) invert(1);
}

#sms-channel.active:hover .filter-red {
    filter: brightness(0) invert(1);
}

#sendSMS-channel {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
    border-radius: 15px;
}

#sendSMS-channel span {
    color: var(--primary-hover, #F05865);
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#sendSMS-channel .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#sendSMS-channel .MuiListItem-button {
    height: 60px;
}

#sendSMS-channel .active {
    background-color: #F05865 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#sendSMS-channel .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#sendSMS-channel .active span {
    color: #ffffff !important;
}

#sendSMS-channel:hover {
    background-color: #F05865 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#sendSMS-channel:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#sendSMS-channel:hover span {
    color: #ffffff !important;
}

#scheduleSMS-channel {
    height: 60px !important;
    display: block;
}

#scheduleSMS-channel span {
    color: var(--primary-hover, #F05865);
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.sms-channel-menu #scheduleSMS-channel .MuiListItem-button {
    color: var(--primary-hover, #F05865);
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /*background-color: #FFEDF0 !important;*/
}

#scheduleSMS-channel .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#scheduleSMS-channel .MuiListItem-button {
    height: 60px;
}

#scheduleSMS-channel .active {
    background-color: #F05865 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#scheduleSMS-channel .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#scheduleSMS-channel .active span {
    color: #ffffff !important;
}

#scheduleSMS-channel:hover {
    background-color: #F05865 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#scheduleSMS-channel:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#scheduleSMS-channel:hover span {
    color: #ffffff !important;
}

/* ================================================================ */
/* ================================================================ */
/* Email Channel - Sidebar */
/* ================================================================ */
/* ================================================================ */

#email-channel.MuiListItem-button.active:hover {
    background-color: #2C87DE;
    height: 60px;
    border-radius: 10px;
}

#email-channel.MuiListItem-button:hover {
    background-color: #DFEDFA;
    height: 60px;
}

.email-channel-menu #email-channel.MuiListItem-button.active {
    background: var(--secondary-900, #2C87DE);
    border-radius: 10px;
}

#email-channel.MuiListItem-button.active .filter-red {
    filter: brightness(0) invert(1);
}

#email-channel .filter-red {
    filter: invert(42%) sepia(93%) saturate(656%) hue-rotate(176deg) brightness(91%) contrast(90%);
}

#email-channel.MuiListItem-button.active span {
    color: #FFF !important;
}

#email-channel.MuiListItem-button {
    height: 60px;
}

#email-channel.active svg {
    color: #FFF;
}

#email-channel .MuiListItemText-root span {
    color: #2C87DE;
    font-family: 'Manrope';
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#email-channel svg {
    color: #2C87DE;
}

#email-channel .filter-red {
    filter: invert(42%) sepia(93%) saturate(656%) hue-rotate(176deg) brightness(91%) contrast(90%);
}

#sendEmail-channel span,
#scheduleEmail-channel span {
    color: #2C87DE !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.email-channel-menu #sendEmail-channel span {
    color: var(--primary-hover, #2C87DE);
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#sendEmail-channel .filter-white {
    filter: invert(42%) sepia(93%) saturate(656%) hue-rotate(176deg) brightness(91%) contrast(90%);
}

#sendEmail-channel {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

.email-channel-menu #sendEmail-channel .MuiListItem-button {
    color: #2C87DE !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#sendEmail-channel .MuiListItem-button {
    height: 60px;
}

#sendEmail-channel .active {
    background-color: #2C87DE !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#sendEmail-channel .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#sendEmail-channel .active span {
    color: #ffffff !important;
}

#sendEmail-channel:hover {
    background-color: #2C87DE !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#sendEmail-channel:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#sendEmail-channel:hover span {
    color: #ffffff !important;
}

.email-channel-menu #scheduleEmail-channel span {
    color: var(--primary-hover, #2C87DE);
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#scheduleEmail-channel .filter-white {
    filter: invert(42%) sepia(93%) saturate(656%) hue-rotate(176deg) brightness(91%) contrast(90%);
}

#scheduleEmail-channel {
    height: 60px !important;
    display: block;
}

#scheduleEmail-channel .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#scheduleEmail-channel .MuiListItem-button {
    height: 60px;
}

#scheduleEmail-channel .active {
    background-color: #2C87DE !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#scheduleEmail-channel .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#scheduleEmail-channel .active span {
    color: #ffffff !important;
}

#scheduleEmail-channel:hover {
    background-color: #2C87DE !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#scheduleEmail-channel:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#scheduleEmail-channel:hover span {
    color: #ffffff !important;
}

/* ================================================================ */
/* ================================================================ */
/* Whatsapp - Sidebar */
/* ================================================================ */
/* ================================================================ */

#wp-channel.MuiListItem-button.active:hover {
    background-color: #27AE60;
    height: 60px;
    border-radius: 10px;
}

#wp-channel.MuiListItem-button:hover {
    background-color: #DFF3E7;
    height: 60px;
}

#wp-channel:hover .MuiListItemText-root span {
    color: #27AE60 !important;
}

#wp-channel.MuiListItem-button.active {
    color: #fff !important;
    background-color: #DFF3E7;
    height: 60px;
    border-radius: 10px;
}

#wp-channel.MuiListItem-button .filter-red {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#wp-channel.MuiListItem-button.active .filter-red {
    filter: brightness(0) invert(1);
}

#wp-channel.MuiListItem-button.active span {
    color: #fff !important;
}

#wp-channel.MuiListItem-button {
    height: 60px;
}

#wp-channel svg {
    color: #27AE60;
}

#wp-channels.active svg {
    color: #fff;
}

#wp-channel svg {
    color: #27AE60;
}

#wp-channel.active svg {
    color: #fff;
}

#wp-channel.active:hover svg {
    color: #fff;
}

#wp-channel .MuiListItemText-root span {
    color: #27AE60;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.wp-channel-menu #wp-channel {
    background-color: #27AE60 !important;
    border-radius: 10px;
}

#wpid span {
    font-size: 18px;
}

#wpid {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

#wpid span {
    color: #27AE60 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#wpid .MuiListItem-button .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#wpid .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#wpid .MuiListItem-button {
    height: 60px;
}

#wpid .active {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#wpid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#wpid .active span {
    color: #ffffff !important;
}

#wpid:hover {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#wpid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#wpid:hover span {
    color: #ffffff !important;
}

#wparchiveid span {
    font-size: 18px;
}

#wparchiveid {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

#wparchiveid span {
    color: #27AE60 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#wparchiveid .MuiListItem-button .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#wwparchiveid .MuiListItem-button.active .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
    color: #ffffff
}

#wparchiveid .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#wparchiveid .MuiListItem-button {
    height: 60px;
}

#wparchiveid .active {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#wparchiveid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#wparchiveid .active span {
    color: #ffffff !important;
}

#wparchiveid:hover {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#wparchiveid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#wparchiveid:hover span {
    color: #ffffff !important;
}

/* ================================================================ */
/* ================================================================ */
/* Analytics - Sidebar */
/* ================================================================ */
/* ================================================================ */

a#analyticsid:hover .MuiListItemText-root span {
    color: #FF74C4 !important;
}

a#analyticsid:hover .MuiListItemIcon-root svg {
    /* color: #FF74C4 !important; */
    stroke: #1d2740
}

#analyticsid.MuiListItem-button {
    height: 60px;
}

#analyticsid.MuiListItem-button span {
    color: #FF74C4;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#analyticsid.MuiListItem-button:hover {
    background-color: #eddce6 !important;
    height: 60px;
    border-radius: 10px;
}

#analyticsid.MuiListItem-button .filter-red {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
}

#analyticsid.MuiListItem-button:hover .filter-red {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
}

#analyticsid.MuiListItem-button.active {
    color: #ffffff !important;
    background-color: #eddce6;
    height: 60px;
    border-radius: 10px;
}

#analyticsid.MuiListItem-button.active:hover {
    background-color: #FF74C4 !important;
    height: 60px;
    border-radius: 10px;
}

#analyticsid.MuiListItem-button.active span {
    color: #FFFFFF !important;
}

a#analyticsid .MuiListItem-button.active svg {
    color: #ffffff !important;
    stroke: #1d2740
}

/* a#analyticsid .MuiListItem-button.active .filter-red {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
} */

/* #analyticsid.MuiListItem-button.active .filter-red {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
} */

#analyticsid.MuiListItem-button.active .filter-red {
    filter: brightness(0) invert(1);
}

.normal-analyticsid-menu #analyticsid.MuiListItem-button.active {
    background: var(--secondary-900, #2C87DE);
    border-radius: 10px;
}

#analyticsid svg {
    color: #FF74C4;
}

#analyticsid.active svg {
    color: #FFF;
}

#analyticsid .filter-red {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
}

.analyticsid-menu #analyticsid.MuiListItem-button.active {
    background: var(--secondary-900, #FF74C4);
    border-radius: 10px;
}

#campaignanalyticsid span,
#historyanalyticsid span {
    color: #FF74C4 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#campaignanalyticsid {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

#campaignanalyticsid:hover {
    background-color: #FF74C4 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#campaignanalyticsid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#campaignanalyticsid:hover span {
    color: #ffffff !important;
}

#campaignanalyticsid .MuiListItem-button {
    height: 60px;
}

#campaignanalyticsid .active {
    background-color: #FF74C4 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#campaignanalyticsid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#campaignanalyticsid .active span {
    color: #ffffff !important;
}

#campaignanalyticsid .filter-white {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
}

.normal-analyticsid-menu #campaignanalyticsid .MuiListItem-button {
    color: #FF74C4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#historyanalyticsid {
    height: 60px !important;
    display: block;
}

#historyanalyticsid:hover {
    background-color: #FF74C4 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

/* #historyanalyticsid .filter-white {
    filter: invert(42%) sepia(93%) saturate(656%) hue-rotate(176deg) brightness(91%) contrast(90%);
} */

#historyanalyticsid .filter-white {
    filter: invert(71%) sepia(50%) saturate(2763%) hue-rotate(289deg) brightness(100%) contrast(102%);
}

#historyanalyticsid .MuiListItem-button {
    height: 60px;
}

.normal-analyticsid-menu #historyanalyticsid .MuiListItem-button {
    color: #FF74C4 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#historyanalyticsid .active {
    background-color: #FF74C4 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#historyanalyticsid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#historyanalyticsid .active span {
    color: #ffffff !important;
}

#historyanalyticsid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#historyanalyticsid:hover span {
    color: #ffffff !important;
}

/* ================================================================ */
/* ================================================================ */
/* Contact - Sidebar */
/* ================================================================ */
/* ================================================================ */

#contact-channel.MuiListItem-button.active:hover {
    background-color: #27AE60;
    height: 60px;
    border-radius: 10px;
}

#contact-channel.MuiListItem-button:hover {
    background-color: #DFF3E7;
    height: 60px;
}

#contact-channel:hover .MuiListItemText-root span {
    color: #27AE60 !important;
}

#contact-channel.MuiListItem-button.active {
    color: #fff !important;
    background-color: #DFF3E7;
    height: 60px;
    border-radius: 10px;
}

#contact-channel.MuiListItem-button .filter-red {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#contact-channel.MuiListItem-button.active .filter-red {
    filter: brightness(0) invert(1);
}

#contact-channel.MuiListItem-button.active span {
    color: #fff !important;
}

#contact-channel.MuiListItem-button {
    height: 60px;
    /*margin:5px 2px;*/
}

#contact-channel svg {
    color: #27AE60;
}

/*#contact-channel:hover svg{color: #27AE60;}*/
#contact-channels.active svg {
    color: #fff;
}

#contact-channel svg {
    color: #27AE60;
}

#contact-channel.active svg {
    color: #fff;
}

#contact-channel.active:hover svg {
    color: #fff;
}

#contact-channel .MuiListItemText-root span {
    color: #27AE60;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.contact-channel-menu #contact-channel {
    background-color: #27AE60 !important;
    border-radius: 10px;
}

.contact-channel-menu #contact-channel {
    background-color: #27AE60 !important;
    border-radius: 10px;
}

#contact-channel.active .normal-contact-channel-menu:hover {
    background: #DFF3E7;
    border-radius: 10px;
}

.makeStyles-list-11 #allcontactsid .MuiListItem-button:hover,
.makeStyles-list-11 #contactlistid .MuiListItem-button:hover {
    background: transparent !important;
}

#contactlistid span {
    font-size: 18px;
}

#contactlistid {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

#contactlistid span {
    color: #27AE60 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#contactlistid .MuiListItem-button .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#wcontactlistid .MuiListItem-button.active .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
    color: #ffffff
}

#contactlistid .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#contactlistid .MuiListItem-button {
    height: 60px;
    /*margin:5px 2px;*/
}

#contactlistid .active {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#contactlistid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#contactlistid .active span {
    color: #ffffff !important;
}

#contactlistid:hover {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#contactlistid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#contactlistid:hover span {
    color: #ffffff !important;
}

#allcontactsid span {
    font-size: 18px;
}

#allcontactsid {
    height: 60px !important;
    margin: 5px 0px 5px 0px;
    display: block;
}

#allcontactsid span {
    color: #27AE60 !important;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#allcontactsid .MuiListItem-button .filter-white {
    filter: invert(57%) sepia(46%) saturate(600%) hue-rotate(93deg) brightness(88%) contrast(101%);
}

#allcontactsid .MuiListItem-button {
    color: #B5B3B4;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

#allcontactsid .MuiListItem-button {
    height: 60px;
    /*margin:5px 2px;*/
}

#allcontactsid .active {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#allcontactsid .active .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#allcontactsid .active span {
    color: #ffffff !important;
}

#allcontactsid:hover {
    background-color: #27AE60 !important;
    height: 60px;
    border-radius: 10px;
    color: #ffffff !important;
}

#allcontactsid:hover .MuiListItemIcon-root .filter-white {
    filter: brightness(0) invert(1);
}

#allcontactsid:hover span {
    color: #ffffff !important;
}
@import url('https://fonts.googleapis.com/css?family=Manrope:regular,Light,Bold,ExtraBold,SemiBold,Medium,ExtraLight,italic');

.userlogin {
  flex: 5;
}

.twoFa {
  font-weight: bold;
  padding-bottom: 10px;
}

.twoFaOptions {
  margin-right: 10px;
  margin-bottom: 10px;
}

.twoFaFields > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #d8d5d6 !important;
  border-radius: 8px;
  color: #b5b3b4 !important;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Manrope';
}

/* Login page response css */
@media screen and (max-width: 768px) {
  .userLoginContainer {
    flex-direction: column;
  }
  .logo-two {
    display: none;
  }
  .userloginLeft {
    border-right: none !important;
    width: 100%;
  }
  .logoadminbottom {
    width: 100%;
  }
  /* .userloginLeft span {
    height: 0% !important;
  } */
}
@media screen and (max-width: 540px) {
  .resetoptionsContainer {
    flex-direction: column;
    align-items: start !important;
  }
}
.userLoginContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;
  min-height: 100vh;
}

.userloginLeft {
  background-color: #ffffff;
  padding: 0px 0px;
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  border-right: 1.5px solid #d8d5d6;
}

.userloginLeft span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.logoadmintop {
  justify-content: left;
  width: 100%;
  height: 300px;
}

.userloginLeft span .logoadmintop img {
  height: 80px;
  margin: 20px;
}

.userloginLeft span .logoadminbottom {
  padding: 10px;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.userloginLeft span .logoadminbottom img {
  max-width: 600px;
  justify-content: center;
  width: 100%;
}

.userloginLeft span .logoadminbottom img.logo {
  width: 100%;
  max-width: 280px;
  /* margin-bottom: 50px; */
}

.userLoginRight {
  padding: 0px 60px 30px;
  background-color: #ffffff;
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}
.userlogin .pageHeaderTitle {
  font-size: clamp(24px, 1.4vw, 36px) !important;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginForm {
  /* display: flex; 
     flex-wrap: wrap; */
  max-width: 600px;
  width: 100%;
}

.loginItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.loginItem > label {
  margin-bottom: 15px;
  color: #2c2126;
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 600;
}

.loginItem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #d8d5d6 !important;
  border-radius: 8px;
  color: #b5b3b4 !important;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Manrope';
}

.loginItem input[type='email'] {
  margin-bottom: 20px;
}

.loginButton {
  width: 100%;
  border-radius: 8px;
  background-color: #ef5663;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  color: white;
  height: 60px;
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 400;
}

.resetoptionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resetoptionsLeft {
  color: #1d2740;
  font-size: 12px;
  padding-top: 5px;
  margin-left: 5px;
  align-content: center;
  justify-content: center;
}

/* .resetoptionsLeft input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border: 1px solid #b5b3b4;
  border-radius: 4px;
  margin-right: 15px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
} */

.forgotpasswordbtn {
  color: #3e3e3e !important;
  /* font-size: 12px; */
  cursor: pointer;
  margin-right: 5px;
  border: none;
  background-color: transparent;
  padding: 5px 0px 0px 0px;
  align-items: center;
  display: flex;
}

.needsanaccount {
  color: #ffffff;
  font-size: 14px;
  /* cursor: pointer; */
  margin-right: 5px;
  margin-top: 20px;
  border: none;
  background-color: transparent;
  padding: 5px 0px 0px 0px;
  text-align: center;
  width: 100%;
}

.needsanaccount span {
  color: #ef5663;
  cursor: pointer;
}

.resetoptionsRight {
  color: #1d2740;
  font-size: 12px;
  padding-top: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.resetDescription {
  color: gray;
  font-size: 14px;
  font-weight: 300;
  padding-left: 15px;
  padding-top: 5px !important;
}

.additionalLogin {
  color: #ffffff;
  text-align: center;
}

.additionalLogin h4 {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.additionalLogin p {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin: 10px 0px;
}

.google-btn {
  width: 100%;
  height: 46px;
  border-radius: 8px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #4f86ec;
  align-content: center;
  max-width: 400px;
  background-color: #4f86ec;
  padding: 1px;
  cursor: pointer;
}

.google-icon-wrapper {
  position: absolute;
  width: 46px;
  height: 42px;
  max-width: 46px;
  border-radius: 8px;
  background-color: #ffffff;
}

.google-icon {
  width: 26px;
  height: 26px;
  margin: 8px auto;
  display: block;
}

.btn-text {
  float: right;
  height: 42px;
  color: #ffffff;
  width: 100%;
  font-size: 16px;
  font-family: 'Manrope';
  font-weight: 400;
  max-width: 550px;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}

/* .checkbox-container {
  display: flex;
  align-items: center;
}

.round {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  background-color: #fff;
  left: -11px;
}

.round:after {
  content: "";
  display: block;
  width: 10px;
  height: 6px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform-origin: center;
  margin-top: -3px;
  margin-left: -6px;
}

input[type="checkbox"] {
  visibility: hidden;
}

.round + input[type="checkbox"]:checked + .round {
  background-color: black;
  border-color: #66bb6a;
}

input[type="checkbox"]:checked + .round:after {
  opacity: 1;
} */

.checkbox-container {
  margin-top: 15px;
}
.checkbox-container input[type='checkbox'] {
  visibility: hidden;
}

.checkbox-container .round {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  background-color: #fff;
  left: -11px;
  top: 5px;
}

.checkbox-container .round:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform-origin: center;
  margin-top: -3px;
  margin-left: -6px;
}

.checkbox-container input[type='checkbox']:checked + .round {
  background-color: black;
  border-color: #66bb6a;
}

.checkbox-container input[type='checkbox']:checked + .round:after {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .userLoginRight {
    padding: 0px 30px 30px;
  }
}

.try-button {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-top: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.try-btn-p {
  display: flex;
  justify-content: center;
}

.identity-back {
  padding-right: 10px;
  font-size: 26px;
  cursor: pointer;
}

.identity-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.identity-cls-one {
  display: flex;
  border-bottom: 1px solid #c1c1c1;
}

.identity-option {
  display: flex;
  flex-direction: column;
  height: 250px;
}

.identity-phone {
  color: black;
  padding-left: 115px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.identity-email {
  color: black;
  padding-left: 115px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.phone-method {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 26px;
  cursor: pointer;
}

.identity-cls {
  height: 375px;
}

.email-method {
  width: 100%;
  display: flex;
  justify-content: end;
  font-size: 26px;
  cursor: pointer;
}

.passwordReset {
  /* flex: 5;
  padding: 50px 40px; */
  /*  display: flex;
  justify-content: space-around; */
  height: 100%;
  display: flex;
  width: 100%;
  /* justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 50px; */
  min-height: 100vh;
}

.reset-password-img {
  border-right: 1px solid #d8d5d6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding-bottom: 30px;
  padding-top: 30px;
  height: auto;
}

.passwordResetForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.passwordResetItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-width: 500px;
}
.passwordResetItem > label {
  margin-bottom: 15px;
  color: #2c2126;
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 600;
}
.passwordResetItem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #d8d5d6 !important;
  border-radius: 8px;
  color: #b5b3b4 !important;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Manrope';
}

.passwordResetButton {
  width: 100%;
  border-radius: 8px;
  background-color: #ef5663;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  color: white;
  height: 60px;
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 400;
}

.passwordReset .reset-password-img img.log {
  max-width: 280px;
  width: 100%;
}
/* .reset-password-img img {
  max-width: 600px;
  justify-content: center;
  width: 100%;
} */

.reset-password-title {
  color: #2c2126 !important;
  font-family: Manrope;
  font-size: clamp(24px, 1.4vw, 36px);
  font-weight: 700;
  margin-bottom: 10px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-password-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.logo-two {
  max-width: 100%;
  max-height: 500px;
  margin: 0 auto;
}
.passwordContainer svg {
  max-width: 13px;
  position: relative;
  top: 3px;
}
@media (max-width: 768px) {
  .passwordResetForm {
    max-width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  /* .userLoginContainer {
      flex-direction: column;
      height: 0% !important;
    } */
  .reset-password-img .logo-two {
    display: none;
  }
  .reset-password-img {
    border: none;
    width: 100% !important;
  }
  .passwordReset {
    flex-direction: column !important;
  }

  .reset-password-form {
    align-items: normal !important;
    width: 100% !important;
    padding-bottom: 20px;
  }

  /* .userloginLeft {
      border-right: none !important;
      width: 100%;
      height: 0% !important;
    }
    .logoadminbottom {
      width: 100%;
    }
    .userloginLeft span {
      height: 0% !important;
    }
  
    .resetoptionsContainer {
      flex-direction: column;
      align-items: start !important;
    } */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reset-password-img .logo-two {
    max-width: 350px !important;
  }

  .passwordResetItem {
    max-width: 350px !important;
  }
  .passwordResetForm {
    padding-top: 20px;
  }
}
